.CustomInput + .form-label {
	display: none !important;
	background-color: red !important;
}

.form-group {
	margin-bottom: 0px !important;
}

.form-builder-outermost-container {
	width: 100%;
	height: 75vh;
	overflow: hidden;
	display: flex;
	gap: 20px;
}

.form-builder-inner-left {
	display: flex;
	width: 80%;
	max-width: 100% !important;
	height: calc(100% - 3vh);
	overflow-y: scroll;
	flex-direction: column;
	flex-wrap: nowrap;
}

.form-builder-inner-right {
	width: 20%;
	min-width: 250px !important;
	height: calc(100% - 8vh);
	background: #eceff1;
}

.toolbar-outer-dropdown {
	width: 100%;
	display: flex;
	justify-content: 'space-between';
	color: 'rgba(0, 0, 0, 0.6)';
	font-size: '14px!important';
	padding: '12px';
}

.formbuilder-toolbar-item {
	border: 1px dashed var(--color-blue);
	display: flex;
	align-items: center;
	background: #ffffff;
	padding: 10px;
	color: var(--color-blue);
	font-weight: 500;
	font-size: 14px;
	gap: 6px;
}

.SortableItem-element-type,
.SortableItem-element-type p {
	min-width: 100px;
	font-weight: 400;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.6);
	text-overflow: ellipsis !important;
	overflow: hidden;
	text-wrap: nowrap;
	margin-bottom: 0px;
}

.react-form-builder .react-form-builder-toolbar {
	min-width: 250px;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	border: 1px solid #90a4ae;
	border-radius: 0px 0px 4px 4px;
	background: #eceff1;
	padding: 0px;
}

.react-form-builder .react-form-builder-toolbar::-webkit-scrollbar-track {
	background: #eceff1;
}

.react-form-builder .react-form-builder-toolbar::-webkit-scrollbar-thumb,
.react-form-builder .form-builder-inner-container::-webkit-scrollbar-thumb {
	border: 3px solid #eceff1;
}

.react-form-builder-form .form-group:has(.hidden-dependancy) .hidden-dependancy,
.react-form-builder-form .form-group:has(.hidden-dependancy) label {
	display: none !important;
}

.react-form-builder-form .SortableItem .form-group:has(.hidden-dependancy):has(.hidden-dependancy) {
	display: none !important;
}

.react-form-builder-form .hidden-dependancy {
	display: none !important;
}

.block-75 {
	display: flex;
	gap: 5px;
	width: 75%;
}

.block-25 {
	display: flex;
	gap: 5px;
	width: 25%;
}

.block-logical {
	padding: 33px 10px 30px 10px;
	background: #eceff1;
	border-radius: 6px;
}

.form-builder-title {
	position: relative;
	width: 100%;
	height: 60px;
	min-height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #90a4ae;
	border-radius: 4px 4px 0px 0px;
	font-weight: 500;
	font-size: 16px;
	color: white;
}

.widthMargin {
	width: calc(100% - 10px) !important;
}

.form-builder-inner-container {
	height: 67vh;
	overflow-y: scroll;
}

.gap-3 {
	gap: 30px !important;
}

.SortableItem {
	overflow: hidden;
}

.toolbar-header-mb {
	margin-bottom: 20px;
}

.multiStep-formViewer .rfb-item .hideLabel {
	display: none !important;
}

.MuiInputBase-root.Mui-disabled {
	background-color: #f8f8f8;
	-webkit-text-fill-color: black;
}

.MuiInputBase-root.Mui-disabled fieldset {
	border-color: #f8f8f8 !important;
}

.ElementIdDisable .MuiInputBase-root.Mui-disabled fieldset {
	border-color: #ede9e9 !important;
}

.form-group .MuiInputBase-input.Mui-disabled {
	-webkit-text-fill-color: black;
}

/* .fixedElement {
	position: fixed !important;
	top: 98px;
	z-index: 100;
	width: 359px !important;
	right: 2%;
	max-height: 80vh;
	overflow: scroll;
} */

.MuiInputLabel-asterisk {
	color: #e65100;
	font-size: 30px;
	vertical-align: -8px;
}

.formbuilder-draggableArea-text {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0px;
}

.formbuilder-draggableArea-businessLogic {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-left: auto;
}

.formbuilder-draggableArea-text p {
	font-weight: 400;
	font-size: 16px;
	color: #78909c;
	margin: 0px;
}

.disabled-text {
	opacity: 0.3;
}

.disabled-section {
	opacity: 0.5;
	pointer-events: none;
}

.MuiDataGrid-rowReorderCell {
	color: var(--color-blue);
}

.disableTableRows .MuiDataGrid-rowReorderCell,
.disableTableRows .MuiButtonBase-root,
.disableTableRows .MuiSvgIcon-root {
	color: grey;
	fill: grey;
	pointer-events: none;
}

.Mui-disabled .MuiSelect-icon {
	display: none;
}

.MuiFormControl-root > .Mui-focused {
	border-color: var(--color-blue) !important;
	border-width: 2px !important;
}

div:has(#formBuilder-meta-input-instructions)::after {
	border-bottom: 0px solid var(--color-blue) !important;
}
div:has(#formBuilder-meta-input-instructions):hover:not(.Mui-disabled, .Mui-error):before {
	border-bottom: 0px solid rgba(0, 0, 0, 0.87) !important;
}
