.TextField-Container {
	position: relative;
}

.box {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
}

.input {
	height: 50px;
	border: 1px solid;
	border-radius: 5px;
	outline: none;
	transform-origin: top left;
	font-size: 1rem;
	background-color: #fff;
	padding: 0px 15px;
}

input[type='textarea'] {
	min-height: 100px;
}

.label {
	position: absolute;
	pointer-events: none;
	top: 12px;
	left: 15px;
	transition: 0.3s all;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
	top: -10px;
	font-size: 0.9rem;
	background-color: #fff;
	padding: 0 5px;
	transition: 0.3s all;
}

.eyebtn {
	position: absolute;
	right: 15px;
	display: flex !important;
	top: 50%;
	transform: translateY(-50%);
}
