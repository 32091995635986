/******* GLOBAL STYLING *******/
* {
	box-sizing: border-box;
}

/******* IMPORTS START *******/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
/******* IMPORTS END *******/

/******* VARIABLES START *******/
:root {
	--main-text-color: rgba(0, 0, 0, 0.87);
	--gray-subtitle: rgba(0, 0, 0, 0.6);
	--color-ecivis-blue: #009dac;
	--color-blue: #1976d2;
	--main-font: 'Roboto';
	--main-weight: 400;
}
/******* VARIABLES END *******/

/******* FONTS START *******/

h4 {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500 !important;
	font-size: 20px !important;
	color: rgba(0, 0, 0, 0.87);
	margin: 15px 0px !important;
	line-height: normal;
}

h5 {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: var(--main-weight);
	font-size: 24px;
	color: var(--main-text-color);
}

h6 {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: var(--main-weight) !important;
	font-size: 14px !important;
	color: var(--gray-subtitle);
	line-height: 21px;
}

p {
	font-family: var(--main-font);
	font-style: normal;
	font-weight: var(--main-weight);
	font-size: 16px;
	color: var(--main-text-color);
}

a {
	color: var(--color-blue);
	cursor: pointer;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}
/******* FONTS END *******/

/******* BUTTONS START *******/
button:focus {
	outline: 0px !important;
}

button {
	font-size: 13px !important;
}
/******* BUTTONS END *******/

/******* MUI COMPONENTS START *******/
.MuiPickersPopper-root {
	z-index: 2000 !important;
}
/******* MUI COMPONENTS END *******/

/******* GLOBAL SCROLLBAR START ********/
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.2) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 10px;
}

*::-webkit-scrollbar-track {
	background: white;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	border: 3px solid white;
}
/******* GLOBAL SCROLLBAR END *******/
